
































import Button from "@/components/Button.vue";
import ContentCard from "@/components/ContentCard.vue";
import Tapbar from "@/components/Tapbar.vue";
import useCancelButton from "@/composables/buttons/useCancelButton";
import useProfile from "@/composables/useProfile";
import { useChangeEmailMutation } from "@/graphql/types";
import ITabContent from "@/interfaces/ITabContent";
import { EditableKey } from "@/symbols";
import { computed, defineComponent, provide, ref } from "@vue/composition-api";
import useVuelidate from "@vuelidate/core";
import { EmailKey } from "../Self-service/symbols";
import EnterEmail from "./components/EnterEmail.vue";
import VerifyEmailVue from "./components/VerifyEmail.vue";

export default defineComponent({
  components: { ContentCard, Button, Tapbar },
  setup(props, { root }) {
    const email = ref("");
    const v$ = useVuelidate();
    const { profile } = useProfile();
    const currentTab = ref(0);

    provide(EmailKey, email);
    provide(EditableKey, ref(true));

    const { mutate, loading, error, onDone } = useChangeEmailMutation({});

    function call() {
      mutate({
        input: {
          email: email.value,
          userId: profile.value?.id,
        },
      });
    }

    onDone(() => {
      currentTab.value++;
    });

    return {
      currentTab,
      loading,
      error,
      tabs: computed<ITabContent[]>(() => [
        {
          title: "enteremail",
          primaryButton: {
            title: root.$tc("common.save"),
            variant: "success",
            onClick: async () => {
              if (await v$.value.$validate()) call();
            },
            disabled:
              (!v$.value.$dirty ?? false) ||
              v$.value.$errors.length > 0 ||
              v$.value.$pending,
          },
          secondaryButton: useCancelButton(() => root.$router.back()),
          component: EnterEmail,
        },
        {
          title: "verifyemail",
          component: VerifyEmailVue,
        },
      ]),
    };
  },
});
