



































import InputGroup from "@/components/InputGroup.vue";
import useProfile from "@/composables/useProfile";
import injectStrict from "@/utilities/injectstrict";
import { email, required } from "@/utilities/validators";
import { defineComponent } from "@vue/composition-api";
import useVuelidate from "@vuelidate/core";
import { EmailKey } from "../symbols";

export default defineComponent({
  components: { InputGroup },
  setup() {
    const { profile } = useProfile();
    const email = injectStrict(EmailKey);

    const v$ = useVuelidate(
      useValidations(),
      {
        email: email,
      },
      { $autoDirty: true }
    );

    return {
      profile,
      v$,
    };
  },
});

function useValidations() {
  return { email: { email, required } };
}
