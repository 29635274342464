












import IButton from "@/interfaces/IButton";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<IButton>,
      required: false,
    },
  },
});
