






































import useProfile from "@/composables/useProfile";
import { useResendEmailMutation } from "@/graphql/types";
import injectStrict from "@/utilities/injectstrict";
import { defineComponent, ref } from "@vue/composition-api";
import { EmailKey } from "../symbols";

export default defineComponent({
  setup() {
    const { profile } = useProfile();
    const email = injectStrict(EmailKey);
    const resendComplete = ref(false);
    const { mutate, loading, onDone } = useResendEmailMutation({});

    function resend() {
      mutate({
        input: profile.value?.id,
      });
    }

    onDone(() => {
      resendComplete.value = true;
    });

    return {
      resendComplete,
      loading,
      email,
      resend,
    };
  },
});
