<template>
  <b-card body-class="d-flex flex-column" class="p-4" bg-variant="white">
    <div
      v-if="isLoading"
      class="d-flex my-5 flex-grow-1 py-5 align-items-center justify-content-center"
    >
      <Spinner />
    </div>
    <template v-else>
      <div class="d-flex align-items-center">
        <h2>{{ title ? title : $route.matched[0].name }}</h2>
        <b-button
          v-if="action"
          :disabled="!editable"
          class="ml-auto"
          variant="primary"
          @click="$emit('clicked')"
          >{{ action }}</b-button
        >
      </div>
      <slot />
    </template>
  </b-card>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import Spinner from "./Spinner.vue";
import useEditableComponentProps from "@/composables/useEditableComponentProps";

export default defineComponent({
  inheritAttrs: false,
  components: { Spinner },
  props: {
    ...useEditableComponentProps(),
    title: {
      type: String,
      required: false,
    },
    action: {
      type: String,
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
