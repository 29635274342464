

































































import {
  computed,
  defineComponent,
  inject,
  PropType,
  ref,
} from "@vue/composition-api";
import { ErrorObject, Validation } from "@vuelidate/core";
import useLowercased from "@/composables/useLowercased";
import useEditableComponentProps from "@/composables/useEditableComponentProps";
import { EditableKey } from "@/symbols";

export default defineComponent({
  emits: ["keydown"],
  props: {
    ...useEditableComponentProps(false),
    type: {
      type: String,
      default: "input",
    },
    v: {
      type: Object as PropType<Validation>,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
    debounce: {
      type: Number,
      default: 0,
    },
    description: {
      type: String,
      required: false,
    },
    errors: {
      type: Array as PropType<ErrorObject[]>,
      default: () => [],
    },
    warning: {
      type: String,
      required: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const editable = inject(EditableKey, ref(false));
    const keydown = (e: any) => {
      emit("keydown", e);
    };

    return {
      disabled: computed(() => (props.editable ? false : !editable.value)),
      useLowercased,
      input: computed<string | undefined>({
        get(): string | undefined {
          return props.v ? (props.v.$model as string) : props.value;
        },
        set(newValue: string | undefined): void {
          props.v ? (props.v.$model = newValue) : emit("input", newValue);
        },
      }),
      mErrors: computed(() => (props.v ? props.v.$errors : props.errors)),
      keydown,
    };
  },
});
